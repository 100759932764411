
let wasm;

const heap = new Array(32).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 36) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

let WASM_VECTOR_LEN = 0;

let cachegetUint8Memory0 = null;
function getUint8Memory0() {
    if (cachegetUint8Memory0 === null || cachegetUint8Memory0.buffer !== wasm.memory.buffer) {
        cachegetUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachegetUint8Memory0;
}

let cachedTextEncoder = new TextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length);
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len);

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3);
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

function isLikeNone(x) {
    return x === undefined || x === null;
}

let cachegetInt32Memory0 = null;
function getInt32Memory0() {
    if (cachegetInt32Memory0 === null || cachegetInt32Memory0.buffer !== wasm.memory.buffer) {
        cachegetInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachegetInt32Memory0;
}

let cachegetFloat64Memory0 = null;
function getFloat64Memory0() {
    if (cachegetFloat64Memory0 === null || cachegetFloat64Memory0.buffer !== wasm.memory.buffer) {
        cachegetFloat64Memory0 = new Float64Array(wasm.memory.buffer);
    }
    return cachegetFloat64Memory0;
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

let cachedTextDecoder = new TextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function debugString(val) {
    // primitive types
    const type = typeof val;
    if (type == 'number' || type == 'boolean' || val == null) {
        return  `${val}`;
    }
    if (type == 'string') {
        return `"${val}"`;
    }
    if (type == 'symbol') {
        const description = val.description;
        if (description == null) {
            return 'Symbol';
        } else {
            return `Symbol(${description})`;
        }
    }
    if (type == 'function') {
        const name = val.name;
        if (typeof name == 'string' && name.length > 0) {
            return `Function(${name})`;
        } else {
            return 'Function';
        }
    }
    // objects
    if (Array.isArray(val)) {
        const length = val.length;
        let debug = '[';
        if (length > 0) {
            debug += debugString(val[0]);
        }
        for(let i = 1; i < length; i++) {
            debug += ', ' + debugString(val[i]);
        }
        debug += ']';
        return debug;
    }
    // Test for built-in
    const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
    let className;
    if (builtInMatches.length > 1) {
        className = builtInMatches[1];
    } else {
        // Failed to match the standard '[object ClassName]'
        return toString.call(val);
    }
    if (className == 'Object') {
        // we're a user defined class or Object
        // JSON.stringify avoids problems with cycles, and is generally much
        // easier than looping through ownProperties of `val`.
        try {
            return 'Object(' + JSON.stringify(val) + ')';
        } catch (_) {
            return 'Object';
        }
    }
    // errors
    if (val instanceof Error) {
        return `${val.name}: ${val.message}\n${val.stack}`;
    }
    // TODO we could test for more things here, like `Set`s and `Map`s.
    return className;
}
/**
*/
export function init_panic_hook() {
    wasm.init_panic_hook();
}

/**
* @param {any} settings
* @returns {any}
*/
export function calculate_pokemon_bdsp_underground(settings) {
    var ret = wasm.calculate_pokemon_bdsp_underground(addHeapObject(settings));
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_bdsp_tid_field_groups() {
    var ret = wasm.get_bdsp_tid_field_groups();
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_bdsp_tid_result_columns() {
    var ret = wasm.get_bdsp_tid_result_columns();
    return takeObject(ret);
}

/**
* @param {any} settings
* @returns {any}
*/
export function generate_tid(settings) {
    var ret = wasm.generate_tid(addHeapObject(settings));
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_transporter_field_groups() {
    var ret = wasm.get_transporter_field_groups();
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_transporter_result_columns() {
    var ret = wasm.get_transporter_result_columns();
    return takeObject(ret);
}

/**
* @param {any} settings
* @returns {any}
*/
export function generate_transporter(settings) {
    var ret = wasm.generate_transporter(addHeapObject(settings));
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_gen3_wild_field_groups() {
    var ret = wasm.get_gen3_wild_field_groups();
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_gen3_wild_result_columns() {
    var ret = wasm.get_gen3_wild_result_columns();
    return takeObject(ret);
}

/**
* @param {any} settings
* @returns {any}
*/
export function generate_gen3_wild(settings) {
    var ret = wasm.generate_gen3_wild(addHeapObject(settings));
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_bdsp_wild_field_groups() {
    var ret = wasm.get_bdsp_wild_field_groups();
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_bdsp_wild_result_columns() {
    var ret = wasm.get_bdsp_wild_result_columns();
    return takeObject(ret);
}

/**
* @param {any} settings
* @returns {any}
*/
export function generate_bdsp_wild(settings) {
    var ret = wasm.generate_bdsp_wild(addHeapObject(settings));
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_bdsp_static_field_groups() {
    var ret = wasm.get_bdsp_static_field_groups();
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_bdsp_static_result_columns() {
    var ret = wasm.get_bdsp_static_result_columns();
    return takeObject(ret);
}

/**
* @param {any} settings
* @returns {any}
*/
export function generate_bdsp_static(settings) {
    var ret = wasm.generate_bdsp_static(addHeapObject(settings));
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_bdsp_underground_field_groups() {
    var ret = wasm.get_bdsp_underground_field_groups();
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_bdsp_underground_result_columns() {
    var ret = wasm.get_bdsp_underground_result_columns();
    return takeObject(ret);
}

/**
* @param {any} settings
* @returns {any}
*/
export function generate_bdsp_underground(settings) {
    var ret = wasm.generate_bdsp_underground(addHeapObject(settings));
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_swsh_overworld_field_groups() {
    var ret = wasm.get_swsh_overworld_field_groups();
    return takeObject(ret);
}

/**
* @returns {any}
*/
export function get_swsh_overworld_result_columns() {
    var ret = wasm.get_swsh_overworld_result_columns();
    return takeObject(ret);
}

/**
* @param {any} settings
* @returns {any}
*/
export function generate_swsh_overworld(settings) {
    var ret = wasm.generate_swsh_overworld(addHeapObject(settings));
    return takeObject(ret);
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        wasm.__wbindgen_exn_store(addHeapObject(e));
    }
}

const u32CvtShim = new Uint32Array(2);

const uint64CvtShim = new BigUint64Array(u32CvtShim.buffer);
/**
*/
export const Gen3Ability = Object.freeze({ Ability0:0,"0":"Ability0",Ability1:1,"1":"Ability1", });
/**
*/
export const Gen3Method = Object.freeze({ H1:0,"0":"H1",H2:1,"1":"H2",H4:2,"2":"H4", });
/**
*/
export const Gen3Lead = Object.freeze({ Synchronize:0,"0":"Synchronize", });
/**
*/
export const ShinyType = Object.freeze({ Star:0,"0":"Star",Square:1,"1":"Square", });
/**
*/
export const Nature = Object.freeze({ Hardy:0,"0":"Hardy",Lonely:1,"1":"Lonely",Brave:2,"2":"Brave",Adamant:3,"3":"Adamant",Naughty:4,"4":"Naughty",Bold:5,"5":"Bold",Docile:6,"6":"Docile",Relaxed:7,"7":"Relaxed",Impish:8,"8":"Impish",Lax:9,"9":"Lax",Timid:10,"10":"Timid",Hasty:11,"11":"Hasty",Serious:12,"12":"Serious",Jolly:13,"13":"Jolly",Naive:14,"14":"Naive",Modest:15,"15":"Modest",Mild:16,"16":"Mild",Quiet:17,"17":"Quiet",Bashful:18,"18":"Bashful",Rash:19,"19":"Rash",Calm:20,"20":"Calm",Gentle:21,"21":"Gentle",Sassy:22,"22":"Sassy",Careful:23,"23":"Careful",Quirky:24,"24":"Quirky", });
/**
*/
export const AbilityNumber = Object.freeze({ Ability0:0,"0":"Ability0",Ability1:1,"1":"Ability1", });
/**
*/
export const EncounterSlot = Object.freeze({ Slot0:0,"0":"Slot0",Slot1:1,"1":"Slot1",Slot2:2,"2":"Slot2",Slot3:3,"3":"Slot3",Slot4:4,"4":"Slot4",Slot5:5,"5":"Slot5",Slot6:6,"6":"Slot6",Slot7:7,"7":"Slot7",Slot8:8,"8":"Slot8",Slot9:9,"9":"Slot9",Slot10:10,"10":"Slot10",Slot11:11,"11":"Slot11", });
/**
*/
export const GenderRatio = Object.freeze({ Genderless:255,"255":"Genderless",Male50Female50:127,"127":"Male50Female50",Male25Female75:191,"191":"Male25Female75",Male75Female25:63,"63":"Male75Female25",Male875Female125:31,"31":"Male875Female125",Male:0,"0":"Male",Female:254,"254":"Female", });
/**
*/
export const Gender = Object.freeze({ Genderless:0,"0":"Genderless",Male:1,"1":"Male",Female:2,"2":"Female", });
/**
*/
export const IDFilter = Object.freeze({ TID:0,"0":"TID",SID:1,"1":"SID",TSV:2,"2":"TSV",G8TID:3,"3":"G8TID", });

async function load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

async function init(input) {
    if (typeof input === 'undefined') {
        input = new URL('chatot_bg.wasm', import.meta.url);
    }
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbindgen_object_drop_ref = function(arg0) {
        takeObject(arg0);
    };
    imports.wbg.__wbindgen_is_bigint = function(arg0) {
        var ret = typeof(getObject(arg0)) === 'bigint';
        return ret;
    };
    imports.wbg.__wbindgen_string_get = function(arg0, arg1) {
        const obj = getObject(arg1);
        var ret = typeof(obj) === 'string' ? obj : undefined;
        var ptr0 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbindgen_boolean_get = function(arg0) {
        const v = getObject(arg0);
        var ret = typeof(v) === 'boolean' ? (v ? 1 : 0) : 2;
        return ret;
    };
    imports.wbg.__wbindgen_is_string = function(arg0) {
        var ret = typeof(getObject(arg0)) === 'string';
        return ret;
    };
    imports.wbg.__wbindgen_is_object = function(arg0) {
        const val = getObject(arg0);
        var ret = typeof(val) === 'object' && val !== null;
        return ret;
    };
    imports.wbg.__wbg_BigInt_6b6f34a01a71ad51 = function(arg0, arg1) {
        var ret = BigInt(getObject(arg1));
        uint64CvtShim[0] = ret;
        const low0 = u32CvtShim[0];
        const high0 = u32CvtShim[1];
        getInt32Memory0()[arg0 / 4 + 1] = high0;
        getInt32Memory0()[arg0 / 4 + 0] = low0;
    };
    imports.wbg.__wbg_BigInt_1a499fbb5f402f4c = function(arg0, arg1) {
        u32CvtShim[0] = arg0;
        u32CvtShim[1] = arg1;
        const n0 = uint64CvtShim[0];
        var ret = BigInt(n0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_is_null = function(arg0) {
        var ret = getObject(arg0) === null;
        return ret;
    };
    imports.wbg.__wbindgen_is_undefined = function(arg0) {
        var ret = getObject(arg0) === undefined;
        return ret;
    };
    imports.wbg.__wbindgen_number_get = function(arg0, arg1) {
        const obj = getObject(arg1);
        var ret = typeof(obj) === 'number' ? obj : undefined;
        getFloat64Memory0()[arg0 / 8 + 1] = isLikeNone(ret) ? 0 : ret;
        getInt32Memory0()[arg0 / 4 + 0] = !isLikeNone(ret);
    };
    imports.wbg.__wbindgen_number_new = function(arg0) {
        var ret = arg0;
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_object_clone_ref = function(arg0) {
        var ret = getObject(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_string_new = function(arg0, arg1) {
        var ret = getStringFromWasm0(arg0, arg1);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_get_093fe3cdafaf8976 = function(arg0, arg1) {
        var ret = getObject(arg0)[takeObject(arg1)];
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_set_e93b31d47b90bff6 = function(arg0, arg1, arg2) {
        getObject(arg0)[takeObject(arg1)] = takeObject(arg2);
    };
    imports.wbg.__wbg_get_67189fe0b323d288 = function(arg0, arg1) {
        var ret = getObject(arg0)[arg1 >>> 0];
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_length_42e02f5a04d67464 = function(arg0) {
        var ret = getObject(arg0).length;
        return ret;
    };
    imports.wbg.__wbg_new_949bbc1147195c4e = function() {
        var ret = new Array();
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_is_function = function(arg0) {
        var ret = typeof(getObject(arg0)) === 'function';
        return ret;
    };
    imports.wbg.__wbg_next_c4151d46d5fa7097 = function(arg0) {
        var ret = getObject(arg0).next;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_next_7720502039b96d00 = function() { return handleError(function (arg0) {
        var ret = getObject(arg0).next();
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_done_b06cf0578e89ff68 = function(arg0) {
        var ret = getObject(arg0).done;
        return ret;
    };
    imports.wbg.__wbg_value_e74a542443d92451 = function(arg0) {
        var ret = getObject(arg0).value;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_iterator_4fc4ce93e6b92958 = function() {
        var ret = Symbol.iterator;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_get_4d0f21c2f823742e = function() { return handleError(function (arg0, arg1) {
        var ret = Reflect.get(getObject(arg0), getObject(arg1));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_call_888d259a5fefc347 = function() { return handleError(function (arg0, arg1) {
        var ret = getObject(arg0).call(getObject(arg1));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_new_0b83d3df67ecb33e = function() {
        var ret = new Object();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_set_1820441f7fb79aad = function(arg0, arg1, arg2) {
        getObject(arg0)[arg1 >>> 0] = takeObject(arg2);
    };
    imports.wbg.__wbg_isArray_eb7ad55f2da67dde = function(arg0) {
        var ret = Array.isArray(getObject(arg0));
        return ret;
    };
    imports.wbg.__wbg_instanceof_ArrayBuffer_764b6d4119231cb3 = function(arg0) {
        var ret = getObject(arg0) instanceof ArrayBuffer;
        return ret;
    };
    imports.wbg.__wbg_new_342a24ca698edd87 = function(arg0, arg1) {
        var ret = new Error(getStringFromWasm0(arg0, arg1));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_isSafeInteger_0dfc6d38b7184f06 = function(arg0) {
        var ret = Number.isSafeInteger(getObject(arg0));
        return ret;
    };
    imports.wbg.__wbg_entries_aadf9c3f38203a12 = function(arg0) {
        var ret = Object.entries(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_is_0f5efc7977a2c50b = function(arg0, arg1) {
        var ret = Object.is(getObject(arg0), getObject(arg1));
        return ret;
    };
    imports.wbg.__wbg_buffer_397eaa4d72ee94dd = function(arg0) {
        var ret = getObject(arg0).buffer;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_new_a7ce447f15ff496f = function(arg0) {
        var ret = new Uint8Array(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_set_969ad0a60e51d320 = function(arg0, arg1, arg2) {
        getObject(arg0).set(getObject(arg1), arg2 >>> 0);
    };
    imports.wbg.__wbg_length_1eb8fc608a0d4cdb = function(arg0) {
        var ret = getObject(arg0).length;
        return ret;
    };
    imports.wbg.__wbg_instanceof_Uint8Array_08a1f3a179095e76 = function(arg0) {
        var ret = getObject(arg0) instanceof Uint8Array;
        return ret;
    };
    imports.wbg.__wbg_new_693216e109162396 = function() {
        var ret = new Error();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_stack_0ddaca5d1abfb52f = function(arg0, arg1) {
        var ret = getObject(arg1).stack;
        var ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbg_error_09919627ac0992f5 = function(arg0, arg1) {
        try {
            console.error(getStringFromWasm0(arg0, arg1));
        } finally {
            wasm.__wbindgen_free(arg0, arg1);
        }
    };
    imports.wbg.__wbindgen_debug_string = function(arg0, arg1) {
        var ret = debugString(getObject(arg1));
        var ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };
    imports.wbg.__wbindgen_memory = function() {
        var ret = wasm.memory;
        return addHeapObject(ret);
    };

    if (typeof input === 'string' || (typeof Request === 'function' && input instanceof Request) || (typeof URL === 'function' && input instanceof URL)) {
        input = fetch(input);
    }



    const { instance, module } = await load(await input, imports);

    wasm = instance.exports;
    init.__wbindgen_wasm_module = module;

    return wasm;
}

export default init;

