{
  "Search": "Search",
  "RNG Info": "RNG Info",
  "Seed": "Seed",
  "Seed 0": "Seed 0",
  "Seed 1": "Seed 1",
  "Seed 2": "Seed 2",
  "Seed 3": "Seed 3",
  "Min Advances": "Min Advances",
  "Max Advances": "Max Advances",
  "Delay": "Delay",
  "Lead": "Lead",
  "None": "None",
  "Synchronize": "シンクロ",
  "Method": "Method",
  "Method H1": "Method H1",
  "Method H2": "Method H2",
  "Method H4": "Method H4",
  "Filters": "Filters",
  "Shiny": "色違い",
  "Any": "Any",
  "Star": "Star",
  "Square": "Square",
  "Star/Square": "Star/Square",
  "Nature": "性格",
  "Ability": "特性",
  "Encounter Slot": "Encounter Slot",
  "Gender Ratio": "Gender Ratio",
  "No Set Gender": "No Set Gender",
  "Genderless": "Genderless",
  "Gender": "性別",
  "Advances": "Advances",
  "Slot": "Slot",
  "IVs": "個体値",
  "PID": "PID",
  "EC": "EC",
  "Min IVs": "Min IVs",
  "Max IVs": "Max IVs",
  "HP": "HP",
  "Attack": "Attack",
  "Defense": "Defense",
  "Special Attack": "Special Attack",
  "Special Defense": "Special Defense",
  "Speed": "Speed",
  "Statue Tiles": "Statue Tiles",
  "Room Size": "Room Size",
  "Small": "Small",
  "Large": "Large",
  "Diglett Boost": "Diglett Boost",
  "Rare": "Rare",
  "ID Filter": "ID Filter",
  "TID": "TID",
  "SID": "SID",
  "TSV": "TSV",
  "Gen 8 TID": "Gen 8 TID",
  "Set IVs": "Set IVs",
  "Roamer": "Roamer",
  "Pokemon": "Pokemon",
  "Gifts": "Gifts",
  "Fossils": "Fossils",
  "Stationary": "Stationary",
  "Roamers": "Roamers",
  "Legends": "Legends",
  "Mythics": "Mythics",
  "Ramanas Park (Pure Space)": "ハマナスパーク (きれいなくうかん)",
  "Ramanas Park (Strange Space)": "ハマナスパーク (ふしぎなくうかん)",
  "No Results Found": "No Results Found",
  "PSV": "PSV",
  "Hidden Power": "Hidden Power",
  "Mew or Celebi": "Mew or Celebi",
  "Shiny Pokemon": "Shiny Pokemon",
  "Click or tap a result to set as target": "Click or tap a result to set as target",
  "Hardy": "がんばりや",
  "Lonely": "さみしがり",
  "Brave": "ゆうかん",
  "Adamant": "いじっぱり",
  "Naughty": "やんちゃ",
  "Bold": "ずぶとい",
  "Docile": "すなお",
  "Relaxed": "のんき",
  "Impish": "わんぱく",
  "Lax": "のうてんき",
  "Timid": "おくびょう",
  "Hasty": "せっかち",
  "Serious": "まじめ",
  "Jolly": "ようき",
  "Naive": "むじゃき",
  "Modest": "ひかえめ",
  "Mild": "おっとり",
  "Quiet": "れいせい",
  "Bashful": "てれや",
  "Rash": "うっかりや",
  "Calm": "おだやか",
  "Gentle": "おとなしい",
  "Sassy": "なまいき",
  "Careful": "しんちょう",
  "Quirky": "きまぐれ",
  "Egg": "タマゴ",
  "Bulbasaur": "フシギダネ",
  "Ivysaur": "フシギソウ",
  "Venusaur": "フシギバナ",
  "Charmander": "ヒトカゲ",
  "Charmeleon": "リザード",
  "Charizard": "リザードン",
  "Squirtle": "ゼニガメ",
  "Wartortle": "カメール",
  "Blastoise": "カメックス",
  "Caterpie": "キャタピー",
  "Metapod": "トランセル",
  "Butterfree": "バタフリー",
  "Weedle": "ビードル",
  "Kakuna": "コクーン",
  "Beedrill": "スピアー",
  "Pidgey": "ポッポ",
  "Pidgeotto": "ピジョン",
  "Pidgeot": "ピジョット",
  "Rattata": "コラッタ",
  "Raticate": "ラッタ",
  "Spearow": "オニスズメ",
  "Fearow": "オニドリル",
  "Ekans": "アーボ",
  "Arbok": "アーボック",
  "Pikachu": "ピカチュウ",
  "Raichu": "ライチュウ",
  "Sandshrew": "サンド",
  "Sandslash": "サンドパン",
  "Nidoran♀": "ニドラン♀",
  "Nidorina": "ニドリーナ",
  "Nidoqueen": "ニドクイン",
  "Nidoran♂": "ニドラン♂",
  "Nidorino": "ニドリーノ",
  "Nidoking": "ニドキング",
  "Clefairy": "ピッピ",
  "Clefable": "ピクシー",
  "Vulpix": "ロコン",
  "Ninetales": "キュウコン",
  "Jigglypuff": "プリン",
  "Wigglytuff": "プクリン",
  "Zubat": "ズバット",
  "Golbat": "ゴルバット",
  "Oddish": "ナゾノクサ",
  "Gloom": "クサイハナ",
  "Vileplume": "ラフレシア",
  "Paras": "パラス",
  "Parasect": "パラセクト",
  "Venonat": "コンパン",
  "Venomoth": "モルフォン",
  "Diglett": "ディグダ",
  "Dugtrio": "ダグトリオ",
  "Meowth": "ニャース",
  "Persian": "ペルシアン",
  "Psyduck": "コダック",
  "Golduck": "ゴルダック",
  "Mankey": "マンキー",
  "Primeape": "オコリザル",
  "Growlithe": "ガーディ",
  "Arcanine": "ウインディ",
  "Poliwag": "ニョロモ",
  "Poliwhirl": "ニョロゾ",
  "Poliwrath": "ニョロボン",
  "Abra": "ケーシィ",
  "Kadabra": "ユンゲラー",
  "Alakazam": "フーディン",
  "Machop": "ワンリキー",
  "Machoke": "ゴーリキー",
  "Machamp": "カイリキー",
  "Bellsprout": "マダツボミ",
  "Weepinbell": "ウツドン",
  "Victreebel": "ウツボット",
  "Tentacool": "メノクラゲ",
  "Tentacruel": "ドククラゲ",
  "Geodude": "イシツブテ",
  "Graveler": "ゴローン",
  "Golem": "ゴローニャ",
  "Ponyta": "ポニータ",
  "Rapidash": "ギャロップ",
  "Slowpoke": "ヤドン",
  "Slowbro": "ヤドラン",
  "Magnemite": "コイル",
  "Magneton": "レアコイル",
  "Farfetch’d": "カモネギ",
  "Doduo": "ドードー",
  "Dodrio": "ドードリオ",
  "Seel": "パウワウ",
  "Dewgong": "ジュゴン",
  "Grimer": "ベトベター",
  "Muk": "ベトベトン",
  "Shellder": "シェルダー",
  "Cloyster": "パルシェン",
  "Gastly": "ゴース",
  "Haunter": "ゴースト",
  "Gengar": "ゲンガー",
  "Onix": "イワーク",
  "Drowzee": "スリープ",
  "Hypno": "スリーパー",
  "Krabby": "クラブ",
  "Kingler": "キングラー",
  "Voltorb": "ビリリダマ",
  "Electrode": "マルマイン",
  "Exeggcute": "タマタマ",
  "Exeggutor": "ナッシー",
  "Cubone": "カラカラ",
  "Marowak": "ガラガラ",
  "Hitmonlee": "サワムラー",
  "Hitmonchan": "エビワラー",
  "Lickitung": "ベロリンガ",
  "Koffing": "ドガース",
  "Weezing": "マタドガス",
  "Rhyhorn": "サイホーン",
  "Rhydon": "サイドン",
  "Chansey": "ラッキー",
  "Tangela": "モンジャラ",
  "Kangaskhan": "ガルーラ",
  "Horsea": "タッツー",
  "Seadra": "シードラ",
  "Goldeen": "トサキント",
  "Seaking": "アズマオウ",
  "Staryu": "ヒトデマン",
  "Starmie": "スターミー",
  "Mr. Mime": "バリヤード",
  "Scyther": "ストライク",
  "Jynx": "ルージュラ",
  "Electabuzz": "エレブー",
  "Magmar": "ブーバー",
  "Pinsir": "カイロス",
  "Tauros": "ケンタロス",
  "Magikarp": "コイキング",
  "Gyarados": "ギャラドス",
  "Lapras": "ラプラス",
  "Ditto": "メタモン",
  "Eevee": "イーブイ",
  "Vaporeon": "シャワーズ",
  "Jolteon": "サンダース",
  "Flareon": "ブースター",
  "Porygon": "ポリゴン",
  "Omanyte": "オムナイト",
  "Omastar": "オムスター",
  "Kabuto": "カブト",
  "Kabutops": "カブトプス",
  "Aerodactyl": "プテラ",
  "Snorlax": "カビゴン",
  "Articuno": "フリーザー",
  "Zapdos": "サンダー",
  "Moltres": "ファイヤー",
  "Dratini": "ミニリュウ",
  "Dragonair": "ハクリュー",
  "Dragonite": "カイリュー",
  "Mewtwo": "ミュウツー",
  "Mew": "ミュウ",
  "Chikorita": "チコリータ",
  "Bayleef": "ベイリーフ",
  "Meganium": "メガニウム",
  "Cyndaquil": "ヒノアラシ",
  "Quilava": "マグマラシ",
  "Typhlosion": "バクフーン",
  "Totodile": "ワニノコ",
  "Croconaw": "アリゲイツ",
  "Feraligatr": "オーダイル",
  "Sentret": "オタチ",
  "Furret": "オオタチ",
  "Hoothoot": "ホーホー",
  "Noctowl": "ヨルノズク",
  "Ledyba": "レディバ",
  "Ledian": "レディアン",
  "Spinarak": "イトマル",
  "Ariados": "アリアドス",
  "Crobat": "クロバット",
  "Chinchou": "チョンチー",
  "Lanturn": "ランターン",
  "Pichu": "ピチュー",
  "Cleffa": "ピィ",
  "Igglybuff": "ププリン",
  "Togepi": "トゲピー",
  "Togetic": "トゲチック",
  "Natu": "ネイティ",
  "Xatu": "ネイティオ",
  "Mareep": "メリープ",
  "Flaaffy": "モココ",
  "Ampharos": "デンリュウ",
  "Bellossom": "キレイハナ",
  "Marill": "マリル",
  "Azumarill": "マリルリ",
  "Sudowoodo": "ウソッキー",
  "Politoed": "ニョロトノ",
  "Hoppip": "ハネッコ",
  "Skiploom": "ポポッコ",
  "Jumpluff": "ワタッコ",
  "Aipom": "エイパム",
  "Sunkern": "ヒマナッツ",
  "Sunflora": "キマワリ",
  "Yanma": "ヤンヤンマ",
  "Wooper": "ウパー",
  "Quagsire": "ヌオー",
  "Espeon": "エーフィ",
  "Umbreon": "ブラッキー",
  "Murkrow": "ヤミカラス",
  "Slowking": "ヤドキング",
  "Misdreavus": "ムウマ",
  "Unown": "アンノーン",
  "Wobbuffet": "ソーナンス",
  "Girafarig": "キリンリキ",
  "Pineco": "クヌギダマ",
  "Forretress": "フォレトス",
  "Dunsparce": "ノコッチ",
  "Gligar": "グライガー",
  "Steelix": "ハガネール",
  "Snubbull": "ブルー",
  "Granbull": "グランブル",
  "Qwilfish": "ハリーセン",
  "Scizor": "ハッサム",
  "Shuckle": "ツボツボ",
  "Heracross": "ヘラクロス",
  "Sneasel": "ニューラ",
  "Teddiursa": "ヒメグマ",
  "Ursaring": "リングマ",
  "Slugma": "マグマッグ",
  "Magcargo": "マグカルゴ",
  "Swinub": "ウリムー",
  "Piloswine": "イノムー",
  "Corsola": "サニーゴ",
  "Remoraid": "テッポウオ",
  "Octillery": "オクタン",
  "Delibird": "デリバード",
  "Mantine": "マンタイン",
  "Skarmory": "エアームド",
  "Houndour": "デルビル",
  "Houndoom": "ヘルガー",
  "Kingdra": "キングドラ",
  "Phanpy": "ゴマゾウ",
  "Donphan": "ドンファン",
  "Porygon2": "ポリゴン２",
  "Stantler": "オドシシ",
  "Smeargle": "ドーブル",
  "Tyrogue": "バルキー",
  "Hitmontop": "カポエラー",
  "Smoochum": "ムチュール",
  "Elekid": "エレキッド",
  "Magby": "ブビィ",
  "Miltank": "ミルタンク",
  "Blissey": "ハピナス",
  "Raikou": "ライコウ",
  "Entei": "エンテイ",
  "Suicune": "スイクン",
  "Larvitar": "ヨーギラス",
  "Pupitar": "サナギラス",
  "Tyranitar": "バンギラス",
  "Lugia": "ルギア",
  "Ho-Oh": "ホウオウ",
  "Celebi": "セレビィ",
  "Treecko": "キモリ",
  "Grovyle": "ジュプトル",
  "Sceptile": "ジュカイン",
  "Torchic": "アチャモ",
  "Combusken": "ワカシャモ",
  "Blaziken": "バシャーモ",
  "Mudkip": "ミズゴロウ",
  "Marshtomp": "ヌマクロー",
  "Swampert": "ラグラージ",
  "Poochyena": "ポチエナ",
  "Mightyena": "グラエナ",
  "Zigzagoon": "ジグザグマ",
  "Linoone": "マッスグマ",
  "Wurmple": "ケムッソ",
  "Silcoon": "カラサリス",
  "Beautifly": "アゲハント",
  "Cascoon": "マユルド",
  "Dustox": "ドクケイル",
  "Lotad": "ハスボー",
  "Lombre": "ハスブレロ",
  "Ludicolo": "ルンパッパ",
  "Seedot": "タネボー",
  "Nuzleaf": "コノハナ",
  "Shiftry": "ダーテング",
  "Taillow": "スバメ",
  "Swellow": "オオスバメ",
  "Wingull": "キャモメ",
  "Pelipper": "ペリッパー",
  "Ralts": "ラルトス",
  "Kirlia": "キルリア",
  "Gardevoir": "サーナイト",
  "Surskit": "アメタマ",
  "Masquerain": "アメモース",
  "Shroomish": "キノココ",
  "Breloom": "キノガッサ",
  "Slakoth": "ナマケロ",
  "Vigoroth": "ヤルキモノ",
  "Slaking": "ケッキング",
  "Nincada": "ツチニン",
  "Ninjask": "テッカニン",
  "Shedinja": "ヌケニン",
  "Whismur": "ゴニョニョ",
  "Loudred": "ドゴーム",
  "Exploud": "バクオング",
  "Makuhita": "マクノシタ",
  "Hariyama": "ハリテヤマ",
  "Azurill": "ルリリ",
  "Nosepass": "ノズパス",
  "Skitty": "エネコ",
  "Delcatty": "エネコロロ",
  "Sableye": "ヤミラミ",
  "Mawile": "クチート",
  "Aron": "ココドラ",
  "Lairon": "コドラ",
  "Aggron": "ボスゴドラ",
  "Meditite": "アサナン",
  "Medicham": "チャーレム",
  "Electrike": "ラクライ",
  "Manectric": "ライボルト",
  "Plusle": "プラスル",
  "Minun": "マイナン",
  "Volbeat": "バルビート",
  "Illumise": "イルミーゼ",
  "Roselia": "ロゼリア",
  "Gulpin": "ゴクリン",
  "Swalot": "マルノーム",
  "Carvanha": "キバニア",
  "Sharpedo": "サメハダー",
  "Wailmer": "ホエルコ",
  "Wailord": "ホエルオー",
  "Numel": "ドンメル",
  "Camerupt": "バクーダ",
  "Torkoal": "コータス",
  "Spoink": "バネブー",
  "Grumpig": "ブーピッグ",
  "Spinda": "パッチール",
  "Trapinch": "ナックラー",
  "Vibrava": "ビブラーバ",
  "Flygon": "フライゴン",
  "Cacnea": "サボネア",
  "Cacturne": "ノクタス",
  "Swablu": "チルット",
  "Altaria": "チルタリス",
  "Zangoose": "ザングース",
  "Seviper": "ハブネーク",
  "Lunatone": "ルナトーン",
  "Solrock": "ソルロック",
  "Barboach": "ドジョッチ",
  "Whiscash": "ナマズン",
  "Corphish": "ヘイガニ",
  "Crawdaunt": "シザリガー",
  "Baltoy": "ヤジロン",
  "Claydol": "ネンドール",
  "Lileep": "リリーラ",
  "Cradily": "ユレイドル",
  "Anorith": "アノプス",
  "Armaldo": "アーマルド",
  "Feebas": "ヒンバス",
  "Milotic": "ミロカロス",
  "Castform": "ポワルン",
  "Kecleon": "カクレオン",
  "Shuppet": "カゲボウズ",
  "Banette": "ジュペッタ",
  "Duskull": "ヨマワル",
  "Dusclops": "サマヨール",
  "Tropius": "トロピウス",
  "Chimecho": "チリーン",
  "Absol": "アブソル",
  "Wynaut": "ソーナノ",
  "Snorunt": "ユキワラシ",
  "Glalie": "オニゴーリ",
  "Spheal": "タマザラシ",
  "Sealeo": "トドグラー",
  "Walrein": "トドゼルガ",
  "Clamperl": "パールル",
  "Huntail": "ハンテール",
  "Gorebyss": "サクラビス",
  "Relicanth": "ジーランス",
  "Luvdisc": "ラブカス",
  "Bagon": "タツベイ",
  "Shelgon": "コモルー",
  "Salamence": "ボーマンダ",
  "Beldum": "ダンバル",
  "Metang": "メタング",
  "Metagross": "メタグロス",
  "Regirock": "レジロック",
  "Regice": "レジアイス",
  "Registeel": "レジスチル",
  "Latias": "ラティアス",
  "Latios": "ラティオス",
  "Kyogre": "カイオーガ",
  "Groudon": "グラードン",
  "Rayquaza": "レックウザ",
  "Jirachi": "ジラーチ",
  "Deoxys": "デオキシス",
  "Turtwig": "ナエトル",
  "Grotle": "ハヤシガメ",
  "Torterra": "ドダイトス",
  "Chimchar": "ヒコザル",
  "Monferno": "モウカザル",
  "Infernape": "ゴウカザル",
  "Piplup": "ポッチャマ",
  "Prinplup": "ポッタイシ",
  "Empoleon": "エンペルト",
  "Starly": "ムックル",
  "Staravia": "ムクバード",
  "Staraptor": "ムクホーク",
  "Bidoof": "ビッパ",
  "Bibarel": "ビーダル",
  "Kricketot": "コロボーシ",
  "Kricketune": "コロトック",
  "Shinx": "コリンク",
  "Luxio": "ルクシオ",
  "Luxray": "レントラー",
  "Budew": "スボミー",
  "Roserade": "ロズレイド",
  "Cranidos": "ズガイドス",
  "Rampardos": "ラムパルド",
  "Shieldon": "タテトプス",
  "Bastiodon": "トリデプス",
  "Burmy": "ミノムッチ",
  "Wormadam": "ミノマダム",
  "Mothim": "ガーメイル",
  "Combee": "ミツハニー",
  "Vespiquen": "ビークイン",
  "Pachirisu": "パチリス",
  "Buizel": "ブイゼル",
  "Floatzel": "フローゼル",
  "Cherubi": "チェリンボ",
  "Cherrim": "チェリム",
  "Shellos": "カラナクシ",
  "Gastrodon": "トリトドン",
  "Ambipom": "エテボース",
  "Drifloon": "フワンテ",
  "Drifblim": "フワライド",
  "Buneary": "ミミロル",
  "Lopunny": "ミミロップ",
  "Mismagius": "ムウマージ",
  "Honchkrow": "ドンカラス",
  "Glameow": "ニャルマー",
  "Purugly": "ブニャット",
  "Chingling": "リーシャン",
  "Stunky": "スカンプー",
  "Skuntank": "スカタンク",
  "Bronzor": "ドーミラー",
  "Bronzong": "ドータクン",
  "Bonsly": "ウソハチ",
  "Mime Jr.": "マネネ",
  "Happiny": "ピンプク",
  "Chatot": "ペラップ",
  "Spiritomb": "ミカルゲ",
  "Gible": "フカマル",
  "Gabite": "ガバイト",
  "Garchomp": "ガブリアス",
  "Munchlax": "ゴンベ",
  "Riolu": "リオル",
  "Lucario": "ルカリオ",
  "Hippopotas": "ヒポポタス",
  "Hippowdon": "カバルドン",
  "Skorupi": "スコルピ",
  "Drapion": "ドラピオン",
  "Croagunk": "グレッグル",
  "Toxicroak": "ドクロッグ",
  "Carnivine": "マスキッパ",
  "Finneon": "ケイコウオ",
  "Lumineon": "ネオラント",
  "Mantyke": "タマンタ",
  "Snover": "ユキカブリ",
  "Abomasnow": "ユキノオー",
  "Weavile": "マニューラ",
  "Magnezone": "ジバコイル",
  "Lickilicky": "ベロベルト",
  "Rhyperior": "ドサイドン",
  "Tangrowth": "モジャンボ",
  "Electivire": "エレキブル",
  "Magmortar": "ブーバーン",
  "Togekiss": "トゲキッス",
  "Yanmega": "メガヤンマ",
  "Leafeon": "リーフィア",
  "Glaceon": "グレイシア",
  "Gliscor": "グライオン",
  "Mamoswine": "マンムー",
  "Porygon-Z": "ポリゴンＺ",
  "Gallade": "エルレイド",
  "Probopass": "ダイノーズ",
  "Dusknoir": "ヨノワール",
  "Froslass": "ユキメノコ",
  "Rotom": "ロトム",
  "Uxie": "ユクシー",
  "Mesprit": "エムリット",
  "Azelf": "アグノム",
  "Dialga": "ディアルガ",
  "Palkia": "パルキア",
  "Heatran": "ヒードラン",
  "Regigigas": "レジギガス",
  "Giratina": "ギラティナ",
  "Cresselia": "クレセリア",
  "Phione": "フィオネ",
  "Manaphy": "マナフィ",
  "Darkrai": "ダークライ",
  "Shaymin": "シェイミ",
  "Arceus": "アルセウス",
  "Victini": "ビクティニ",
  "Snivy": "ツタージャ",
  "Servine": "ジャノビー",
  "Serperior": "ジャローダ",
  "Tepig": "ポカブ",
  "Pignite": "チャオブー",
  "Emboar": "エンブオー",
  "Oshawott": "ミジュマル",
  "Dewott": "フタチマル",
  "Samurott": "ダイケンキ",
  "Patrat": "ミネズミ",
  "Watchog": "ミルホッグ",
  "Lillipup": "ヨーテリー",
  "Herdier": "ハーデリア",
  "Stoutland": "ムーランド",
  "Purrloin": "チョロネコ",
  "Liepard": "レパルダス",
  "Pansage": "ヤナップ",
  "Simisage": "ヤナッキー",
  "Pansear": "バオップ",
  "Simisear": "バオッキー",
  "Panpour": "ヒヤップ",
  "Simipour": "ヒヤッキー",
  "Munna": "ムンナ",
  "Musharna": "ムシャーナ",
  "Pidove": "マメパト",
  "Tranquill": "ハトーボー",
  "Unfezant": "ケンホロウ",
  "Blitzle": "シママ",
  "Zebstrika": "ゼブライカ",
  "Roggenrola": "ダンゴロ",
  "Boldore": "ガントル",
  "Gigalith": "ギガイアス",
  "Woobat": "コロモリ",
  "Swoobat": "ココロモリ",
  "Drilbur": "モグリュー",
  "Excadrill": "ドリュウズ",
  "Audino": "タブンネ",
  "Timburr": "ドッコラー",
  "Gurdurr": "ドテッコツ",
  "Conkeldurr": "ローブシン",
  "Tympole": "オタマロ",
  "Palpitoad": "ガマガル",
  "Seismitoad": "ガマゲロゲ",
  "Throh": "ナゲキ",
  "Sawk": "ダゲキ",
  "Sewaddle": "クルミル",
  "Swadloon": "クルマユ",
  "Leavanny": "ハハコモリ",
  "Venipede": "フシデ",
  "Whirlipede": "ホイーガ",
  "Scolipede": "ペンドラー",
  "Cottonee": "モンメン",
  "Whimsicott": "エルフーン",
  "Petilil": "チュリネ",
  "Lilligant": "ドレディア",
  "Basculin": "バスラオ",
  "Sandile": "メグロコ",
  "Krokorok": "ワルビル",
  "Krookodile": "ワルビアル",
  "Darumaka": "ダルマッカ",
  "Darmanitan": "ヒヒダルマ",
  "Maractus": "マラカッチ",
  "Dwebble": "イシズマイ",
  "Crustle": "イワパレス",
  "Scraggy": "ズルッグ",
  "Scrafty": "ズルズキン",
  "Sigilyph": "シンボラー",
  "Yamask": "デスマス",
  "Cofagrigus": "デスカーン",
  "Tirtouga": "プロトーガ",
  "Carracosta": "アバゴーラ",
  "Archen": "アーケン",
  "Archeops": "アーケオス",
  "Trubbish": "ヤブクロン",
  "Garbodor": "ダストダス",
  "Zorua": "ゾロア",
  "Zoroark": "ゾロアーク",
  "Minccino": "チラーミィ",
  "Cinccino": "チラチーノ",
  "Gothita": "ゴチム",
  "Gothorita": "ゴチミル",
  "Gothitelle": "ゴチルゼル",
  "Solosis": "ユニラン",
  "Duosion": "ダブラン",
  "Reuniclus": "ランクルス",
  "Ducklett": "コアルヒー",
  "Swanna": "スワンナ",
  "Vanillite": "バニプッチ",
  "Vanillish": "バニリッチ",
  "Vanilluxe": "バイバニラ",
  "Deerling": "シキジカ",
  "Sawsbuck": "メブキジカ",
  "Emolga": "エモンガ",
  "Karrablast": "カブルモ",
  "Escavalier": "シュバルゴ",
  "Foongus": "タマゲタケ",
  "Amoonguss": "モロバレル",
  "Frillish": "プルリル",
  "Jellicent": "ブルンゲル",
  "Alomomola": "ママンボウ",
  "Joltik": "バチュル",
  "Galvantula": "デンチュラ",
  "Ferroseed": "テッシード",
  "Ferrothorn": "ナットレイ",
  "Klink": "ギアル",
  "Klang": "ギギアル",
  "Klinklang": "ギギギアル",
  "Tynamo": "シビシラス",
  "Eelektrik": "シビビール",
  "Eelektross": "シビルドン",
  "Elgyem": "リグレー",
  "Beheeyem": "オーベム",
  "Litwick": "ヒトモシ",
  "Lampent": "ランプラー",
  "Chandelure": "シャンデラ",
  "Axew": "キバゴ",
  "Fraxure": "オノンド",
  "Haxorus": "オノノクス",
  "Cubchoo": "クマシュン",
  "Beartic": "ツンベアー",
  "Cryogonal": "フリージオ",
  "Shelmet": "チョボマキ",
  "Accelgor": "アギルダー",
  "Stunfisk": "マッギョ",
  "Mienfoo": "コジョフー",
  "Mienshao": "コジョンド",
  "Druddigon": "クリムガン",
  "Golett": "ゴビット",
  "Golurk": "ゴルーグ",
  "Pawniard": "コマタナ",
  "Bisharp": "キリキザン",
  "Bouffalant": "バッフロン",
  "Rufflet": "ワシボン",
  "Braviary": "ウォーグル",
  "Vullaby": "バルチャイ",
  "Mandibuzz": "バルジーナ",
  "Heatmor": "クイタラン",
  "Durant": "アイアント",
  "Deino": "モノズ",
  "Zweilous": "ジヘッド",
  "Hydreigon": "サザンドラ",
  "Larvesta": "メラルバ",
  "Volcarona": "ウルガモス",
  "Cobalion": "コバルオン",
  "Terrakion": "テラキオン",
  "Virizion": "ビリジオン",
  "Tornadus": "トルネロス",
  "Thundurus": "ボルトロス",
  "Reshiram": "レシラム",
  "Zekrom": "ゼクロム",
  "Landorus": "ランドロス",
  "Kyurem": "キュレム",
  "Keldeo": "ケルディオ",
  "Meloetta": "メロエッタ",
  "Genesect": "ゲノセクト",
  "Chespin": "ハリマロン",
  "Quilladin": "ハリボーグ",
  "Chesnaught": "ブリガロン",
  "Fennekin": "フォッコ",
  "Braixen": "テールナー",
  "Delphox": "マフォクシー",
  "Froakie": "ケロマツ",
  "Frogadier": "ゲコガシラ",
  "Greninja": "ゲッコウガ",
  "Bunnelby": "ホルビー",
  "Diggersby": "ホルード",
  "Fletchling": "ヤヤコマ",
  "Fletchinder": "ヒノヤコマ",
  "Talonflame": "ファイアロー",
  "Scatterbug": "コフキムシ",
  "Spewpa": "コフーライ",
  "Vivillon": "ビビヨン",
  "Litleo": "シシコ",
  "Pyroar": "カエンジシ",
  "Flabébé": "フラベベ",
  "Floette": "フラエッテ",
  "Florges": "フラージェス",
  "Skiddo": "メェークル",
  "Gogoat": "ゴーゴート",
  "Pancham": "ヤンチャム",
  "Pangoro": "ゴロンダ",
  "Furfrou": "トリミアン",
  "Espurr": "ニャスパー",
  "Meowstic": "ニャオニクス",
  "Honedge": "ヒトツキ",
  "Doublade": "ニダンギル",
  "Aegislash": "ギルガルド",
  "Spritzee": "シュシュプ",
  "Aromatisse": "フレフワン",
  "Swirlix": "ペロッパフ",
  "Slurpuff": "ペロリーム",
  "Inkay": "マーイーカ",
  "Malamar": "カラマネロ",
  "Binacle": "カメテテ",
  "Barbaracle": "ガメノデス",
  "Skrelp": "クズモー",
  "Dragalge": "ドラミドロ",
  "Clauncher": "ウデッポウ",
  "Clawitzer": "ブロスター",
  "Helioptile": "エリキテル",
  "Heliolisk": "エレザード",
  "Tyrunt": "チゴラス",
  "Tyrantrum": "ガチゴラス",
  "Amaura": "アマルス",
  "Aurorus": "アマルルガ",
  "Sylveon": "ニンフィア",
  "Hawlucha": "ルチャブル",
  "Dedenne": "デデンネ",
  "Carbink": "メレシー",
  "Goomy": "ヌメラ",
  "Sliggoo": "ヌメイル",
  "Goodra": "ヌメルゴン",
  "Klefki": "クレッフィ",
  "Phantump": "ボクレー",
  "Trevenant": "オーロット",
  "Pumpkaboo": "バケッチャ",
  "Gourgeist": "パンプジン",
  "Bergmite": "カチコール",
  "Avalugg": "クレベース",
  "Noibat": "オンバット",
  "Noivern": "オンバーン",
  "Xerneas": "ゼルネアス",
  "Yveltal": "イベルタル",
  "Zygarde": "ジガルデ",
  "Diancie": "ディアンシー",
  "Hoopa": "フーパ",
  "Volcanion": "ボルケニオン",
  "Rowlet": "モクロー",
  "Dartrix": "フクスロー",
  "Decidueye": "ジュナイパー",
  "Litten": "ニャビー",
  "Torracat": "ニャヒート",
  "Incineroar": "ガオガエン",
  "Popplio": "アシマリ",
  "Brionne": "オシャマリ",
  "Primarina": "アシレーヌ",
  "Pikipek": "ツツケラ",
  "Trumbeak": "ケララッパ",
  "Toucannon": "ドデカバシ",
  "Yungoos": "ヤングース",
  "Gumshoos": "デカグース",
  "Grubbin": "アゴジムシ",
  "Charjabug": "デンヂムシ",
  "Vikavolt": "クワガノン",
  "Crabrawler": "マケンカニ",
  "Crabominable": "ケケンカニ",
  "Oricorio": "オドリドリ",
  "Cutiefly": "アブリー",
  "Ribombee": "アブリボン",
  "Rockruff": "イワンコ",
  "Lycanroc": "ルガルガン",
  "Wishiwashi": "ヨワシ",
  "Mareanie": "ヒドイデ",
  "Toxapex": "ドヒドイデ",
  "Mudbray": "ドロバンコ",
  "Mudsdale": "バンバドロ",
  "Dewpider": "シズクモ",
  "Araquanid": "オニシズクモ",
  "Fomantis": "カリキリ",
  "Lurantis": "ラランテス",
  "Morelull": "ネマシュ",
  "Shiinotic": "マシェード",
  "Salandit": "ヤトウモリ",
  "Salazzle": "エンニュート",
  "Stufful": "ヌイコグマ",
  "Bewear": "キテルグマ",
  "Bounsweet": "アマカジ",
  "Steenee": "アママイコ",
  "Tsareena": "アマージョ",
  "Comfey": "キュワワー",
  "Oranguru": "ヤレユータン",
  "Passimian": "ナゲツケサル",
  "Wimpod": "コソクムシ",
  "Golisopod": "グソクムシャ",
  "Sandygast": "スナバァ",
  "Palossand": "シロデスナ",
  "Pyukumuku": "ナマコブシ",
  "Type: Null": "タイプ：ヌル",
  "Silvally": "シルヴァディ",
  "Minior": "メテノ",
  "Komala": "ネッコアラ",
  "Turtonator": "バクガメス",
  "Togedemaru": "トゲデマル",
  "Mimikyu": "ミミッキュ",
  "Bruxish": "ハギギシリ",
  "Drampa": "ジジーロン",
  "Dhelmise": "ダダリン",
  "Jangmo-o": "ジャラコ",
  "Hakamo-o": "ジャランゴ",
  "Kommo-o": "ジャラランガ",
  "Tapu Koko": "カプ・コケコ",
  "Tapu Lele": "カプ・テテフ",
  "Tapu Bulu": "カプ・ブルル",
  "Tapu Fini": "カプ・レヒレ",
  "Cosmog": "コスモッグ",
  "Cosmoem": "コスモウム",
  "Solgaleo": "ソルガレオ",
  "Lunala": "ルナアーラ",
  "Nihilego": "ウツロイド",
  "Buzzwole": "マッシブーン",
  "Pheromosa": "フェローチェ",
  "Xurkitree": "デンジュモク",
  "Celesteela": "テッカグヤ",
  "Kartana": "カミツルギ",
  "Guzzlord": "アクジキング",
  "Necrozma": "ネクロズマ",
  "Magearna": "マギアナ",
  "Marshadow": "マーシャドー",
  "Poipole": "ベベノム",
  "Naganadel": "アーゴヨン",
  "Stakataka": "ツンデツンデ",
  "Blacephalon": "ズガドーン",
  "Zeraora": "ゼラオラ",
  "Meltan": "メルタン",
  "Melmetal": "メルメタル",
  "Grookey": "サルノリ",
  "Thwackey": "バチンキー",
  "Rillaboom": "ゴリランダー",
  "Scorbunny": "ヒバニー",
  "Raboot": "ラビフット",
  "Cinderace": "エースバーン",
  "Sobble": "メッソン",
  "Drizzile": "ジメレオン",
  "Inteleon": "インテレオン",
  "Skwovet": "ホシガリス",
  "Greedent": "ヨクバリス",
  "Rookidee": "ココガラ",
  "Corvisquire": "アオガラス",
  "Corviknight": "アーマーガア",
  "Blipbug": "サッチムシ",
  "Dottler": "レドームシ",
  "Orbeetle": "イオルブ",
  "Nickit": "クスネ",
  "Thievul": "フォクスライ",
  "Gossifleur": "ヒメンカ",
  "Eldegoss": "ワタシラガ",
  "Wooloo": "ウールー",
  "Dubwool": "バイウールー",
  "Chewtle": "カムカメ",
  "Drednaw": "カジリガメ",
  "Yamper": "ワンパチ",
  "Boltund": "パルスワン",
  "Rolycoly": "タンドン",
  "Carkol": "トロッゴン",
  "Coalossal": "セキタンザン",
  "Applin": "カジッチュ",
  "Flapple": "アップリュー",
  "Appletun": "タルップル",
  "Silicobra": "スナヘビ",
  "Sandaconda": "サダイジャ",
  "Cramorant": "ウッウ",
  "Arrokuda": "サシカマス",
  "Barraskewda": "カマスジョー",
  "Toxel": "エレズン",
  "Toxtricity": "ストリンダー",
  "Sizzlipede": "ヤクデ",
  "Centiskorch": "マルヤクデ",
  "Clobbopus": "タタッコ",
  "Grapploct": "オトスパス",
  "Sinistea": "ヤバチャ",
  "Polteageist": "ポットデス",
  "Hatenna": "ミブリム",
  "Hattrem": "テブリム",
  "Hatterene": "ブリムオン",
  "Impidimp": "ベロバー",
  "Morgrem": "ギモー",
  "Grimmsnarl": "オーロンゲ",
  "Obstagoon": "タチフサグマ",
  "Perrserker": "ニャイキング",
  "Cursola": "サニゴーン",
  "Sirfetch’d": "ネギガナイト",
  "Mr. Rime": "バリコオル",
  "Runerigus": "デスバーン",
  "Milcery": "マホミル",
  "Alcremie": "マホイップ",
  "Falinks": "タイレーツ",
  "Pincurchin": "バチンウニ",
  "Snom": "ユキハミ",
  "Frosmoth": "モスノウ",
  "Stonjourner": "イシヘンジン",
  "Eiscue": "コオリッポ",
  "Indeedee": "イエッサン",
  "Morpeko": "モルペコ",
  "Cufant": "ゾウドウ",
  "Copperajah": "ダイオウドウ",
  "Dracozolt": "パッチラゴン",
  "Arctozolt": "パッチルドン",
  "Dracovish": "ウオノラゴン",
  "Arctovish": "ウオチルドン",
  "Duraludon": "ジュラルドン",
  "Dreepy": "ドラメシヤ",
  "Drakloak": "ドロンチ",
  "Dragapult": "ドラパルト",
  "Zacian": "ザシアン",
  "Zamazenta": "ザマゼンタ",
  "Eternatus": "ムゲンダイナ",
  "Kubfu": "ダクマ",
  "Urshifu": "ウーラオス",
  "Zarude": "ザルード",
  "Regieleki": "レジエレキ",
  "Regidrago": "レジドラゴ",
  "Glastrier": "ブリザポス",
  "Spectrier": "レイスポス",
  "Calyrex": "バドレックス",
  "Wyrdeer": "アヤシシ",
  "Kleavor": "バサギリ",
  "Ursaluna": "ガチグマ",
  "Basculegion": "イダイトウ",
  "Sneasler": "オオニューラ",
  "Overqwil": "ハリーマン",
  "Enamorus": "ラブトロス"
}
