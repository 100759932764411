{
  "Search": "Search",
  "RNG Info": "RNG Info",
  "Seed": "Seed",
  "Seed 0": "Seed 0",
  "Seed 1": "Seed 1",
  "Seed 2": "Seed 2",
  "Seed 3": "Seed 3",
  "Min Advances": "Min Advances",
  "Max Advances": "Max Advances",
  "Delay": "Delay",
  "Lead": "Lead",
  "None": "None",
  "Synchronize": "Synchronize",
  "Method": "Method",
  "Method H1": "Method H1",
  "Method H2": "Method H2",
  "Method H4": "Method H4",
  "Filters": "Filters",
  "Shiny": "Shiny",
  "Any": "Any",
  "Star": "Star",
  "Square": "Square",
  "Star/Square": "Star/Square",
  "Nature": "Nature",
  "Ability": "Ability",
  "Encounter Slot": "Encounter Slot",
  "Gender Ratio": "Gender Ratio",
  "No Set Gender": "No Set Gender",
  "Genderless": "Genderless",
  "Gender": "Gender",
  "Advances": "Advances",
  "Slot": "Slot",
  "IVs": "IVs",
  "PID": "PID",
  "EC": "EC",
  "Min IVs": "Min IVs",
  "Max IVs": "Max IVs",
  "HP": "HP",
  "Attack": "Attack",
  "Defense": "Defense",
  "Special Attack": "Special Attack",
  "Special Defense": "Special Defense",
  "Speed": "Speed",
  "Statue Tiles": "Statue Tiles",
  "Room Size": "Room Size",
  "Small": "Small",
  "Large": "Large",
  "Diglett Boost": "Diglett Boost",
  "Rare": "Rare",
  "ID Filter": "ID Filter",
  "TID": "TID",
  "SID": "SID",
  "TSV": "TSV",
  "Gen 8 TID": "Gen 8 TID",
  "Set IVs": "Set IVs",
  "Roamer": "Roamer",
  "Pokemon": "Pokemon",
  "Gifts": "Gifts",
  "Fossils": "Fossils",
  "Stationary": "Stationary",
  "Roamers": "Roamers",
  "Legends": "Legends",
  "Mythics": "Mythics",
  "Ramanas Park (Pure Space)": "Ramanas Park (Pure Space)",
  "Ramanas Park (Strange Space)": "Ramanas Park (Strange Space)",
  "No Results Found": "No Results Found",
  "PSV": "PSV",
  "Hidden Power": "Hidden Power",
  "Mew or Celebi": "Mew or Celebi",
  "Shiny Pokemon": "Shiny Pokemon",
  "Click or tap a result to set as target": "Click or tap a result to set as target",
  "Fighting": "Fighting",
  "Flying": "Flying",
  "Poison": "Poison",
  "Ground": "Ground",
  "Rock": "Rock",
  "Bug": "Bug",
  "Ghost": "Ghost",
  "Steel": "Steel",
  "Fire": "Fire",
  "Water": "Water",
  "Grass": "Grass",
  "Electric": "Electric",
  "Psychic": "Psychic",
  "Ice": "Ice",
  "Dragon": "Dragon",
  "Dark": "Dark",
  "Fairy": "Fairy",
  "Hardy": "Hardy",
  "Lonely": "Lonely",
  "Brave": "Brave",
  "Adamant": "Adamant",
  "Naughty": "Naughty",
  "Bold": "Bold",
  "Docile": "Docile",
  "Relaxed": "Relaxed",
  "Impish": "Impish",
  "Lax": "Lax",
  "Timid": "Timid",
  "Hasty": "Hasty",
  "Serious": "Serious",
  "Jolly": "Jolly",
  "Naive": "Naive",
  "Modest": "Modest",
  "Mild": "Mild",
  "Quiet": "Quiet",
  "Bashful": "Bashful",
  "Rash": "Rash",
  "Calm": "Calm",
  "Gentle": "Gentle",
  "Sassy": "Sassy",
  "Careful": "Careful",
  "Quirky": "Quirky",
  "Egg": "Egg",
  "Bulbasaur": "Bulbasaur",
  "Ivysaur": "Ivysaur",
  "Venusaur": "Venusaur",
  "Charmander": "Charmander",
  "Charmeleon": "Charmeleon",
  "Charizard": "Charizard",
  "Squirtle": "Squirtle",
  "Wartortle": "Wartortle",
  "Blastoise": "Blastoise",
  "Caterpie": "Caterpie",
  "Metapod": "Metapod",
  "Butterfree": "Butterfree",
  "Weedle": "Weedle",
  "Kakuna": "Kakuna",
  "Beedrill": "Beedrill",
  "Pidgey": "Pidgey",
  "Pidgeotto": "Pidgeotto",
  "Pidgeot": "Pidgeot",
  "Rattata": "Rattata",
  "Raticate": "Raticate",
  "Spearow": "Spearow",
  "Fearow": "Fearow",
  "Ekans": "Ekans",
  "Arbok": "Arbok",
  "Pikachu": "Pikachu",
  "Raichu": "Raichu",
  "Sandshrew": "Sandshrew",
  "Sandslash": "Sandslash",
  "Nidoran♀": "Nidoran♀",
  "Nidorina": "Nidorina",
  "Nidoqueen": "Nidoqueen",
  "Nidoran♂": "Nidoran♂",
  "Nidorino": "Nidorino",
  "Nidoking": "Nidoking",
  "Clefairy": "Clefairy",
  "Clefable": "Clefable",
  "Vulpix": "Vulpix",
  "Ninetales": "Ninetales",
  "Jigglypuff": "Jigglypuff",
  "Wigglytuff": "Wigglytuff",
  "Zubat": "Zubat",
  "Golbat": "Golbat",
  "Oddish": "Oddish",
  "Gloom": "Gloom",
  "Vileplume": "Vileplume",
  "Paras": "Paras",
  "Parasect": "Parasect",
  "Venonat": "Venonat",
  "Venomoth": "Venomoth",
  "Diglett": "Diglett",
  "Dugtrio": "Dugtrio",
  "Meowth": "Meowth",
  "Persian": "Persian",
  "Psyduck": "Psyduck",
  "Golduck": "Golduck",
  "Mankey": "Mankey",
  "Primeape": "Primeape",
  "Growlithe": "Growlithe",
  "Arcanine": "Arcanine",
  "Poliwag": "Poliwag",
  "Poliwhirl": "Poliwhirl",
  "Poliwrath": "Poliwrath",
  "Abra": "Abra",
  "Kadabra": "Kadabra",
  "Alakazam": "Alakazam",
  "Machop": "Machop",
  "Machoke": "Machoke",
  "Machamp": "Machamp",
  "Bellsprout": "Bellsprout",
  "Weepinbell": "Weepinbell",
  "Victreebel": "Victreebel",
  "Tentacool": "Tentacool",
  "Tentacruel": "Tentacruel",
  "Geodude": "Geodude",
  "Graveler": "Graveler",
  "Golem": "Golem",
  "Ponyta": "Ponyta",
  "Rapidash": "Rapidash",
  "Slowpoke": "Slowpoke",
  "Slowbro": "Slowbro",
  "Magnemite": "Magnemite",
  "Magneton": "Magneton",
  "Farfetch’d": "Farfetch’d",
  "Doduo": "Doduo",
  "Dodrio": "Dodrio",
  "Seel": "Seel",
  "Dewgong": "Dewgong",
  "Grimer": "Grimer",
  "Muk": "Muk",
  "Shellder": "Shellder",
  "Cloyster": "Cloyster",
  "Gastly": "Gastly",
  "Haunter": "Haunter",
  "Gengar": "Gengar",
  "Onix": "Onix",
  "Drowzee": "Drowzee",
  "Hypno": "Hypno",
  "Krabby": "Krabby",
  "Kingler": "Kingler",
  "Voltorb": "Voltorb",
  "Electrode": "Electrode",
  "Exeggcute": "Exeggcute",
  "Exeggutor": "Exeggutor",
  "Cubone": "Cubone",
  "Marowak": "Marowak",
  "Hitmonlee": "Hitmonlee",
  "Hitmonchan": "Hitmonchan",
  "Lickitung": "Lickitung",
  "Koffing": "Koffing",
  "Weezing": "Weezing",
  "Rhyhorn": "Rhyhorn",
  "Rhydon": "Rhydon",
  "Chansey": "Chansey",
  "Tangela": "Tangela",
  "Kangaskhan": "Kangaskhan",
  "Horsea": "Horsea",
  "Seadra": "Seadra",
  "Goldeen": "Goldeen",
  "Seaking": "Seaking",
  "Staryu": "Staryu",
  "Starmie": "Starmie",
  "Mr. Mime": "Mr. Mime",
  "Scyther": "Scyther",
  "Jynx": "Jynx",
  "Electabuzz": "Electabuzz",
  "Magmar": "Magmar",
  "Pinsir": "Pinsir",
  "Tauros": "Tauros",
  "Magikarp": "Magikarp",
  "Gyarados": "Gyarados",
  "Lapras": "Lapras",
  "Ditto": "Ditto",
  "Eevee": "Eevee",
  "Vaporeon": "Vaporeon",
  "Jolteon": "Jolteon",
  "Flareon": "Flareon",
  "Porygon": "Porygon",
  "Omanyte": "Omanyte",
  "Omastar": "Omastar",
  "Kabuto": "Kabuto",
  "Kabutops": "Kabutops",
  "Aerodactyl": "Aerodactyl",
  "Snorlax": "Snorlax",
  "Articuno": "Articuno",
  "Zapdos": "Zapdos",
  "Moltres": "Moltres",
  "Dratini": "Dratini",
  "Dragonair": "Dragonair",
  "Dragonite": "Dragonite",
  "Mewtwo": "Mewtwo",
  "Mew": "Mew",
  "Chikorita": "Chikorita",
  "Bayleef": "Bayleef",
  "Meganium": "Meganium",
  "Cyndaquil": "Cyndaquil",
  "Quilava": "Quilava",
  "Typhlosion": "Typhlosion",
  "Totodile": "Totodile",
  "Croconaw": "Croconaw",
  "Feraligatr": "Feraligatr",
  "Sentret": "Sentret",
  "Furret": "Furret",
  "Hoothoot": "Hoothoot",
  "Noctowl": "Noctowl",
  "Ledyba": "Ledyba",
  "Ledian": "Ledian",
  "Spinarak": "Spinarak",
  "Ariados": "Ariados",
  "Crobat": "Crobat",
  "Chinchou": "Chinchou",
  "Lanturn": "Lanturn",
  "Pichu": "Pichu",
  "Cleffa": "Cleffa",
  "Igglybuff": "Igglybuff",
  "Togepi": "Togepi",
  "Togetic": "Togetic",
  "Natu": "Natu",
  "Xatu": "Xatu",
  "Mareep": "Mareep",
  "Flaaffy": "Flaaffy",
  "Ampharos": "Ampharos",
  "Bellossom": "Bellossom",
  "Marill": "Marill",
  "Azumarill": "Azumarill",
  "Sudowoodo": "Sudowoodo",
  "Politoed": "Politoed",
  "Hoppip": "Hoppip",
  "Skiploom": "Skiploom",
  "Jumpluff": "Jumpluff",
  "Aipom": "Aipom",
  "Sunkern": "Sunkern",
  "Sunflora": "Sunflora",
  "Yanma": "Yanma",
  "Wooper": "Wooper",
  "Quagsire": "Quagsire",
  "Espeon": "Espeon",
  "Umbreon": "Umbreon",
  "Murkrow": "Murkrow",
  "Slowking": "Slowking",
  "Misdreavus": "Misdreavus",
  "Unown": "Unown",
  "Wobbuffet": "Wobbuffet",
  "Girafarig": "Girafarig",
  "Pineco": "Pineco",
  "Forretress": "Forretress",
  "Dunsparce": "Dunsparce",
  "Gligar": "Gligar",
  "Steelix": "Steelix",
  "Snubbull": "Snubbull",
  "Granbull": "Granbull",
  "Qwilfish": "Qwilfish",
  "Scizor": "Scizor",
  "Shuckle": "Shuckle",
  "Heracross": "Heracross",
  "Sneasel": "Sneasel",
  "Teddiursa": "Teddiursa",
  "Ursaring": "Ursaring",
  "Slugma": "Slugma",
  "Magcargo": "Magcargo",
  "Swinub": "Swinub",
  "Piloswine": "Piloswine",
  "Corsola": "Corsola",
  "Remoraid": "Remoraid",
  "Octillery": "Octillery",
  "Delibird": "Delibird",
  "Mantine": "Mantine",
  "Skarmory": "Skarmory",
  "Houndour": "Houndour",
  "Houndoom": "Houndoom",
  "Kingdra": "Kingdra",
  "Phanpy": "Phanpy",
  "Donphan": "Donphan",
  "Porygon2": "Porygon2",
  "Stantler": "Stantler",
  "Smeargle": "Smeargle",
  "Tyrogue": "Tyrogue",
  "Hitmontop": "Hitmontop",
  "Smoochum": "Smoochum",
  "Elekid": "Elekid",
  "Magby": "Magby",
  "Miltank": "Miltank",
  "Blissey": "Blissey",
  "Raikou": "Raikou",
  "Entei": "Entei",
  "Suicune": "Suicune",
  "Larvitar": "Larvitar",
  "Pupitar": "Pupitar",
  "Tyranitar": "Tyranitar",
  "Lugia": "Lugia",
  "Ho-Oh": "Ho-Oh",
  "Celebi": "Celebi",
  "Treecko": "Treecko",
  "Grovyle": "Grovyle",
  "Sceptile": "Sceptile",
  "Torchic": "Torchic",
  "Combusken": "Combusken",
  "Blaziken": "Blaziken",
  "Mudkip": "Mudkip",
  "Marshtomp": "Marshtomp",
  "Swampert": "Swampert",
  "Poochyena": "Poochyena",
  "Mightyena": "Mightyena",
  "Zigzagoon": "Zigzagoon",
  "Linoone": "Linoone",
  "Wurmple": "Wurmple",
  "Silcoon": "Silcoon",
  "Beautifly": "Beautifly",
  "Cascoon": "Cascoon",
  "Dustox": "Dustox",
  "Lotad": "Lotad",
  "Lombre": "Lombre",
  "Ludicolo": "Ludicolo",
  "Seedot": "Seedot",
  "Nuzleaf": "Nuzleaf",
  "Shiftry": "Shiftry",
  "Taillow": "Taillow",
  "Swellow": "Swellow",
  "Wingull": "Wingull",
  "Pelipper": "Pelipper",
  "Ralts": "Ralts",
  "Kirlia": "Kirlia",
  "Gardevoir": "Gardevoir",
  "Surskit": "Surskit",
  "Masquerain": "Masquerain",
  "Shroomish": "Shroomish",
  "Breloom": "Breloom",
  "Slakoth": "Slakoth",
  "Vigoroth": "Vigoroth",
  "Slaking": "Slaking",
  "Nincada": "Nincada",
  "Ninjask": "Ninjask",
  "Shedinja": "Shedinja",
  "Whismur": "Whismur",
  "Loudred": "Loudred",
  "Exploud": "Exploud",
  "Makuhita": "Makuhita",
  "Hariyama": "Hariyama",
  "Azurill": "Azurill",
  "Nosepass": "Nosepass",
  "Skitty": "Skitty",
  "Delcatty": "Delcatty",
  "Sableye": "Sableye",
  "Mawile": "Mawile",
  "Aron": "Aron",
  "Lairon": "Lairon",
  "Aggron": "Aggron",
  "Meditite": "Meditite",
  "Medicham": "Medicham",
  "Electrike": "Electrike",
  "Manectric": "Manectric",
  "Plusle": "Plusle",
  "Minun": "Minun",
  "Volbeat": "Volbeat",
  "Illumise": "Illumise",
  "Roselia": "Roselia",
  "Gulpin": "Gulpin",
  "Swalot": "Swalot",
  "Carvanha": "Carvanha",
  "Sharpedo": "Sharpedo",
  "Wailmer": "Wailmer",
  "Wailord": "Wailord",
  "Numel": "Numel",
  "Camerupt": "Camerupt",
  "Torkoal": "Torkoal",
  "Spoink": "Spoink",
  "Grumpig": "Grumpig",
  "Spinda": "Spinda",
  "Trapinch": "Trapinch",
  "Vibrava": "Vibrava",
  "Flygon": "Flygon",
  "Cacnea": "Cacnea",
  "Cacturne": "Cacturne",
  "Swablu": "Swablu",
  "Altaria": "Altaria",
  "Zangoose": "Zangoose",
  "Seviper": "Seviper",
  "Lunatone": "Lunatone",
  "Solrock": "Solrock",
  "Barboach": "Barboach",
  "Whiscash": "Whiscash",
  "Corphish": "Corphish",
  "Crawdaunt": "Crawdaunt",
  "Baltoy": "Baltoy",
  "Claydol": "Claydol",
  "Lileep": "Lileep",
  "Cradily": "Cradily",
  "Anorith": "Anorith",
  "Armaldo": "Armaldo",
  "Feebas": "Feebas",
  "Milotic": "Milotic",
  "Castform": "Castform",
  "Kecleon": "Kecleon",
  "Shuppet": "Shuppet",
  "Banette": "Banette",
  "Duskull": "Duskull",
  "Dusclops": "Dusclops",
  "Tropius": "Tropius",
  "Chimecho": "Chimecho",
  "Absol": "Absol",
  "Wynaut": "Wynaut",
  "Snorunt": "Snorunt",
  "Glalie": "Glalie",
  "Spheal": "Spheal",
  "Sealeo": "Sealeo",
  "Walrein": "Walrein",
  "Clamperl": "Clamperl",
  "Huntail": "Huntail",
  "Gorebyss": "Gorebyss",
  "Relicanth": "Relicanth",
  "Luvdisc": "Luvdisc",
  "Bagon": "Bagon",
  "Shelgon": "Shelgon",
  "Salamence": "Salamence",
  "Beldum": "Beldum",
  "Metang": "Metang",
  "Metagross": "Metagross",
  "Regirock": "Regirock",
  "Regice": "Regice",
  "Registeel": "Registeel",
  "Latias": "Latias",
  "Latios": "Latios",
  "Kyogre": "Kyogre",
  "Groudon": "Groudon",
  "Rayquaza": "Rayquaza",
  "Jirachi": "Jirachi",
  "Deoxys": "Deoxys",
  "Turtwig": "Turtwig",
  "Grotle": "Grotle",
  "Torterra": "Torterra",
  "Chimchar": "Chimchar",
  "Monferno": "Monferno",
  "Infernape": "Infernape",
  "Piplup": "Piplup",
  "Prinplup": "Prinplup",
  "Empoleon": "Empoleon",
  "Starly": "Starly",
  "Staravia": "Staravia",
  "Staraptor": "Staraptor",
  "Bidoof": "Bidoof",
  "Bibarel": "Bibarel",
  "Kricketot": "Kricketot",
  "Kricketune": "Kricketune",
  "Shinx": "Shinx",
  "Luxio": "Luxio",
  "Luxray": "Luxray",
  "Budew": "Budew",
  "Roserade": "Roserade",
  "Cranidos": "Cranidos",
  "Rampardos": "Rampardos",
  "Shieldon": "Shieldon",
  "Bastiodon": "Bastiodon",
  "Burmy": "Burmy",
  "Wormadam": "Wormadam",
  "Mothim": "Mothim",
  "Combee": "Combee",
  "Vespiquen": "Vespiquen",
  "Pachirisu": "Pachirisu",
  "Buizel": "Buizel",
  "Floatzel": "Floatzel",
  "Cherubi": "Cherubi",
  "Cherrim": "Cherrim",
  "Shellos": "Shellos",
  "Gastrodon": "Gastrodon",
  "Ambipom": "Ambipom",
  "Drifloon": "Drifloon",
  "Drifblim": "Drifblim",
  "Buneary": "Buneary",
  "Lopunny": "Lopunny",
  "Mismagius": "Mismagius",
  "Honchkrow": "Honchkrow",
  "Glameow": "Glameow",
  "Purugly": "Purugly",
  "Chingling": "Chingling",
  "Stunky": "Stunky",
  "Skuntank": "Skuntank",
  "Bronzor": "Bronzor",
  "Bronzong": "Bronzong",
  "Bonsly": "Bonsly",
  "Mime Jr.": "Mime Jr.",
  "Happiny": "Happiny",
  "Chatot": "Chatot",
  "Spiritomb": "Spiritomb",
  "Gible": "Gible",
  "Gabite": "Gabite",
  "Garchomp": "Garchomp",
  "Munchlax": "Munchlax",
  "Riolu": "Riolu",
  "Lucario": "Lucario",
  "Hippopotas": "Hippopotas",
  "Hippowdon": "Hippowdon",
  "Skorupi": "Skorupi",
  "Drapion": "Drapion",
  "Croagunk": "Croagunk",
  "Toxicroak": "Toxicroak",
  "Carnivine": "Carnivine",
  "Finneon": "Finneon",
  "Lumineon": "Lumineon",
  "Mantyke": "Mantyke",
  "Snover": "Snover",
  "Abomasnow": "Abomasnow",
  "Weavile": "Weavile",
  "Magnezone": "Magnezone",
  "Lickilicky": "Lickilicky",
  "Rhyperior": "Rhyperior",
  "Tangrowth": "Tangrowth",
  "Electivire": "Electivire",
  "Magmortar": "Magmortar",
  "Togekiss": "Togekiss",
  "Yanmega": "Yanmega",
  "Leafeon": "Leafeon",
  "Glaceon": "Glaceon",
  "Gliscor": "Gliscor",
  "Mamoswine": "Mamoswine",
  "Porygon-Z": "Porygon-Z",
  "Gallade": "Gallade",
  "Probopass": "Probopass",
  "Dusknoir": "Dusknoir",
  "Froslass": "Froslass",
  "Rotom": "Rotom",
  "Uxie": "Uxie",
  "Mesprit": "Mesprit",
  "Azelf": "Azelf",
  "Dialga": "Dialga",
  "Palkia": "Palkia",
  "Heatran": "Heatran",
  "Regigigas": "Regigigas",
  "Giratina": "Giratina",
  "Cresselia": "Cresselia",
  "Phione": "Phione",
  "Manaphy": "Manaphy",
  "Darkrai": "Darkrai",
  "Shaymin": "Shaymin",
  "Arceus": "Arceus",
  "Victini": "Victini",
  "Snivy": "Snivy",
  "Servine": "Servine",
  "Serperior": "Serperior",
  "Tepig": "Tepig",
  "Pignite": "Pignite",
  "Emboar": "Emboar",
  "Oshawott": "Oshawott",
  "Dewott": "Dewott",
  "Samurott": "Samurott",
  "Patrat": "Patrat",
  "Watchog": "Watchog",
  "Lillipup": "Lillipup",
  "Herdier": "Herdier",
  "Stoutland": "Stoutland",
  "Purrloin": "Purrloin",
  "Liepard": "Liepard",
  "Pansage": "Pansage",
  "Simisage": "Simisage",
  "Pansear": "Pansear",
  "Simisear": "Simisear",
  "Panpour": "Panpour",
  "Simipour": "Simipour",
  "Munna": "Munna",
  "Musharna": "Musharna",
  "Pidove": "Pidove",
  "Tranquill": "Tranquill",
  "Unfezant": "Unfezant",
  "Blitzle": "Blitzle",
  "Zebstrika": "Zebstrika",
  "Roggenrola": "Roggenrola",
  "Boldore": "Boldore",
  "Gigalith": "Gigalith",
  "Woobat": "Woobat",
  "Swoobat": "Swoobat",
  "Drilbur": "Drilbur",
  "Excadrill": "Excadrill",
  "Audino": "Audino",
  "Timburr": "Timburr",
  "Gurdurr": "Gurdurr",
  "Conkeldurr": "Conkeldurr",
  "Tympole": "Tympole",
  "Palpitoad": "Palpitoad",
  "Seismitoad": "Seismitoad",
  "Throh": "Throh",
  "Sawk": "Sawk",
  "Sewaddle": "Sewaddle",
  "Swadloon": "Swadloon",
  "Leavanny": "Leavanny",
  "Venipede": "Venipede",
  "Whirlipede": "Whirlipede",
  "Scolipede": "Scolipede",
  "Cottonee": "Cottonee",
  "Whimsicott": "Whimsicott",
  "Petilil": "Petilil",
  "Lilligant": "Lilligant",
  "Basculin": "Basculin",
  "Sandile": "Sandile",
  "Krokorok": "Krokorok",
  "Krookodile": "Krookodile",
  "Darumaka": "Darumaka",
  "Darmanitan": "Darmanitan",
  "Maractus": "Maractus",
  "Dwebble": "Dwebble",
  "Crustle": "Crustle",
  "Scraggy": "Scraggy",
  "Scrafty": "Scrafty",
  "Sigilyph": "Sigilyph",
  "Yamask": "Yamask",
  "Cofagrigus": "Cofagrigus",
  "Tirtouga": "Tirtouga",
  "Carracosta": "Carracosta",
  "Archen": "Archen",
  "Archeops": "Archeops",
  "Trubbish": "Trubbish",
  "Garbodor": "Garbodor",
  "Zorua": "Zorua",
  "Zoroark": "Zoroark",
  "Minccino": "Minccino",
  "Cinccino": "Cinccino",
  "Gothita": "Gothita",
  "Gothorita": "Gothorita",
  "Gothitelle": "Gothitelle",
  "Solosis": "Solosis",
  "Duosion": "Duosion",
  "Reuniclus": "Reuniclus",
  "Ducklett": "Ducklett",
  "Swanna": "Swanna",
  "Vanillite": "Vanillite",
  "Vanillish": "Vanillish",
  "Vanilluxe": "Vanilluxe",
  "Deerling": "Deerling",
  "Sawsbuck": "Sawsbuck",
  "Emolga": "Emolga",
  "Karrablast": "Karrablast",
  "Escavalier": "Escavalier",
  "Foongus": "Foongus",
  "Amoonguss": "Amoonguss",
  "Frillish": "Frillish",
  "Jellicent": "Jellicent",
  "Alomomola": "Alomomola",
  "Joltik": "Joltik",
  "Galvantula": "Galvantula",
  "Ferroseed": "Ferroseed",
  "Ferrothorn": "Ferrothorn",
  "Klink": "Klink",
  "Klang": "Klang",
  "Klinklang": "Klinklang",
  "Tynamo": "Tynamo",
  "Eelektrik": "Eelektrik",
  "Eelektross": "Eelektross",
  "Elgyem": "Elgyem",
  "Beheeyem": "Beheeyem",
  "Litwick": "Litwick",
  "Lampent": "Lampent",
  "Chandelure": "Chandelure",
  "Axew": "Axew",
  "Fraxure": "Fraxure",
  "Haxorus": "Haxorus",
  "Cubchoo": "Cubchoo",
  "Beartic": "Beartic",
  "Cryogonal": "Cryogonal",
  "Shelmet": "Shelmet",
  "Accelgor": "Accelgor",
  "Stunfisk": "Stunfisk",
  "Mienfoo": "Mienfoo",
  "Mienshao": "Mienshao",
  "Druddigon": "Druddigon",
  "Golett": "Golett",
  "Golurk": "Golurk",
  "Pawniard": "Pawniard",
  "Bisharp": "Bisharp",
  "Bouffalant": "Bouffalant",
  "Rufflet": "Rufflet",
  "Braviary": "Braviary",
  "Vullaby": "Vullaby",
  "Mandibuzz": "Mandibuzz",
  "Heatmor": "Heatmor",
  "Durant": "Durant",
  "Deino": "Deino",
  "Zweilous": "Zweilous",
  "Hydreigon": "Hydreigon",
  "Larvesta": "Larvesta",
  "Volcarona": "Volcarona",
  "Cobalion": "Cobalion",
  "Terrakion": "Terrakion",
  "Virizion": "Virizion",
  "Tornadus": "Tornadus",
  "Thundurus": "Thundurus",
  "Reshiram": "Reshiram",
  "Zekrom": "Zekrom",
  "Landorus": "Landorus",
  "Kyurem": "Kyurem",
  "Keldeo": "Keldeo",
  "Meloetta": "Meloetta",
  "Genesect": "Genesect",
  "Chespin": "Chespin",
  "Quilladin": "Quilladin",
  "Chesnaught": "Chesnaught",
  "Fennekin": "Fennekin",
  "Braixen": "Braixen",
  "Delphox": "Delphox",
  "Froakie": "Froakie",
  "Frogadier": "Frogadier",
  "Greninja": "Greninja",
  "Bunnelby": "Bunnelby",
  "Diggersby": "Diggersby",
  "Fletchling": "Fletchling",
  "Fletchinder": "Fletchinder",
  "Talonflame": "Talonflame",
  "Scatterbug": "Scatterbug",
  "Spewpa": "Spewpa",
  "Vivillon": "Vivillon",
  "Litleo": "Litleo",
  "Pyroar": "Pyroar",
  "Flabébé": "Flabébé",
  "Floette": "Floette",
  "Florges": "Florges",
  "Skiddo": "Skiddo",
  "Gogoat": "Gogoat",
  "Pancham": "Pancham",
  "Pangoro": "Pangoro",
  "Furfrou": "Furfrou",
  "Espurr": "Espurr",
  "Meowstic": "Meowstic",
  "Honedge": "Honedge",
  "Doublade": "Doublade",
  "Aegislash": "Aegislash",
  "Spritzee": "Spritzee",
  "Aromatisse": "Aromatisse",
  "Swirlix": "Swirlix",
  "Slurpuff": "Slurpuff",
  "Inkay": "Inkay",
  "Malamar": "Malamar",
  "Binacle": "Binacle",
  "Barbaracle": "Barbaracle",
  "Skrelp": "Skrelp",
  "Dragalge": "Dragalge",
  "Clauncher": "Clauncher",
  "Clawitzer": "Clawitzer",
  "Helioptile": "Helioptile",
  "Heliolisk": "Heliolisk",
  "Tyrunt": "Tyrunt",
  "Tyrantrum": "Tyrantrum",
  "Amaura": "Amaura",
  "Aurorus": "Aurorus",
  "Sylveon": "Sylveon",
  "Hawlucha": "Hawlucha",
  "Dedenne": "Dedenne",
  "Carbink": "Carbink",
  "Goomy": "Goomy",
  "Sliggoo": "Sliggoo",
  "Goodra": "Goodra",
  "Klefki": "Klefki",
  "Phantump": "Phantump",
  "Trevenant": "Trevenant",
  "Pumpkaboo": "Pumpkaboo",
  "Gourgeist": "Gourgeist",
  "Bergmite": "Bergmite",
  "Avalugg": "Avalugg",
  "Noibat": "Noibat",
  "Noivern": "Noivern",
  "Xerneas": "Xerneas",
  "Yveltal": "Yveltal",
  "Zygarde": "Zygarde",
  "Diancie": "Diancie",
  "Hoopa": "Hoopa",
  "Volcanion": "Volcanion",
  "Rowlet": "Rowlet",
  "Dartrix": "Dartrix",
  "Decidueye": "Decidueye",
  "Litten": "Litten",
  "Torracat": "Torracat",
  "Incineroar": "Incineroar",
  "Popplio": "Popplio",
  "Brionne": "Brionne",
  "Primarina": "Primarina",
  "Pikipek": "Pikipek",
  "Trumbeak": "Trumbeak",
  "Toucannon": "Toucannon",
  "Yungoos": "Yungoos",
  "Gumshoos": "Gumshoos",
  "Grubbin": "Grubbin",
  "Charjabug": "Charjabug",
  "Vikavolt": "Vikavolt",
  "Crabrawler": "Crabrawler",
  "Crabominable": "Crabominable",
  "Oricorio": "Oricorio",
  "Cutiefly": "Cutiefly",
  "Ribombee": "Ribombee",
  "Rockruff": "Rockruff",
  "Lycanroc": "Lycanroc",
  "Wishiwashi": "Wishiwashi",
  "Mareanie": "Mareanie",
  "Toxapex": "Toxapex",
  "Mudbray": "Mudbray",
  "Mudsdale": "Mudsdale",
  "Dewpider": "Dewpider",
  "Araquanid": "Araquanid",
  "Fomantis": "Fomantis",
  "Lurantis": "Lurantis",
  "Morelull": "Morelull",
  "Shiinotic": "Shiinotic",
  "Salandit": "Salandit",
  "Salazzle": "Salazzle",
  "Stufful": "Stufful",
  "Bewear": "Bewear",
  "Bounsweet": "Bounsweet",
  "Steenee": "Steenee",
  "Tsareena": "Tsareena",
  "Comfey": "Comfey",
  "Oranguru": "Oranguru",
  "Passimian": "Passimian",
  "Wimpod": "Wimpod",
  "Golisopod": "Golisopod",
  "Sandygast": "Sandygast",
  "Palossand": "Palossand",
  "Pyukumuku": "Pyukumuku",
  "Type: Null": "Type: Null",
  "Silvally": "Silvally",
  "Minior": "Minior",
  "Komala": "Komala",
  "Turtonator": "Turtonator",
  "Togedemaru": "Togedemaru",
  "Mimikyu": "Mimikyu",
  "Bruxish": "Bruxish",
  "Drampa": "Drampa",
  "Dhelmise": "Dhelmise",
  "Jangmo-o": "Jangmo-o",
  "Hakamo-o": "Hakamo-o",
  "Kommo-o": "Kommo-o",
  "Tapu Koko": "Tapu Koko",
  "Tapu Lele": "Tapu Lele",
  "Tapu Bulu": "Tapu Bulu",
  "Tapu Fini": "Tapu Fini",
  "Cosmog": "Cosmog",
  "Cosmoem": "Cosmoem",
  "Solgaleo": "Solgaleo",
  "Lunala": "Lunala",
  "Nihilego": "Nihilego",
  "Buzzwole": "Buzzwole",
  "Pheromosa": "Pheromosa",
  "Xurkitree": "Xurkitree",
  "Celesteela": "Celesteela",
  "Kartana": "Kartana",
  "Guzzlord": "Guzzlord",
  "Necrozma": "Necrozma",
  "Magearna": "Magearna",
  "Marshadow": "Marshadow",
  "Poipole": "Poipole",
  "Naganadel": "Naganadel",
  "Stakataka": "Stakataka",
  "Blacephalon": "Blacephalon",
  "Zeraora": "Zeraora",
  "Meltan": "Meltan",
  "Melmetal": "Melmetal",
  "Grookey": "Grookey",
  "Thwackey": "Thwackey",
  "Rillaboom": "Rillaboom",
  "Scorbunny": "Scorbunny",
  "Raboot": "Raboot",
  "Cinderace": "Cinderace",
  "Sobble": "Sobble",
  "Drizzile": "Drizzile",
  "Inteleon": "Inteleon",
  "Skwovet": "Skwovet",
  "Greedent": "Greedent",
  "Rookidee": "Rookidee",
  "Corvisquire": "Corvisquire",
  "Corviknight": "Corviknight",
  "Blipbug": "Blipbug",
  "Dottler": "Dottler",
  "Orbeetle": "Orbeetle",
  "Nickit": "Nickit",
  "Thievul": "Thievul",
  "Gossifleur": "Gossifleur",
  "Eldegoss": "Eldegoss",
  "Wooloo": "Wooloo",
  "Dubwool": "Dubwool",
  "Chewtle": "Chewtle",
  "Drednaw": "Drednaw",
  "Yamper": "Yamper",
  "Boltund": "Boltund",
  "Rolycoly": "Rolycoly",
  "Carkol": "Carkol",
  "Coalossal": "Coalossal",
  "Applin": "Applin",
  "Flapple": "Flapple",
  "Appletun": "Appletun",
  "Silicobra": "Silicobra",
  "Sandaconda": "Sandaconda",
  "Cramorant": "Cramorant",
  "Arrokuda": "Arrokuda",
  "Barraskewda": "Barraskewda",
  "Toxel": "Toxel",
  "Toxtricity": "Toxtricity",
  "Sizzlipede": "Sizzlipede",
  "Centiskorch": "Centiskorch",
  "Clobbopus": "Clobbopus",
  "Grapploct": "Grapploct",
  "Sinistea": "Sinistea",
  "Polteageist": "Polteageist",
  "Hatenna": "Hatenna",
  "Hattrem": "Hattrem",
  "Hatterene": "Hatterene",
  "Impidimp": "Impidimp",
  "Morgrem": "Morgrem",
  "Grimmsnarl": "Grimmsnarl",
  "Obstagoon": "Obstagoon",
  "Perrserker": "Perrserker",
  "Cursola": "Cursola",
  "Sirfetch’d": "Sirfetch’d",
  "Mr. Rime": "Mr. Rime",
  "Runerigus": "Runerigus",
  "Milcery": "Milcery",
  "Alcremie": "Alcremie",
  "Falinks": "Falinks",
  "Pincurchin": "Pincurchin",
  "Snom": "Snom",
  "Frosmoth": "Frosmoth",
  "Stonjourner": "Stonjourner",
  "Eiscue": "Eiscue",
  "Indeedee": "Indeedee",
  "Morpeko": "Morpeko",
  "Cufant": "Cufant",
  "Copperajah": "Copperajah",
  "Dracozolt": "Dracozolt",
  "Arctozolt": "Arctozolt",
  "Dracovish": "Dracovish",
  "Arctovish": "Arctovish",
  "Duraludon": "Duraludon",
  "Dreepy": "Dreepy",
  "Drakloak": "Drakloak",
  "Dragapult": "Dragapult",
  "Zacian": "Zacian",
  "Zamazenta": "Zamazenta",
  "Eternatus": "Eternatus",
  "Kubfu": "Kubfu",
  "Urshifu": "Urshifu",
  "Zarude": "Zarude",
  "Regieleki": "Regieleki",
  "Regidrago": "Regidrago",
  "Glastrier": "Glastrier",
  "Spectrier": "Spectrier",
  "Calyrex": "Calyrex",
  "Wyrdeer": "Wyrdeer",
  "Kleavor": "Kleavor",
  "Ursaluna": "Ursaluna",
  "Basculegion": "Basculegion",
  "Sneasler": "Sneasler",
  "Overqwil": "Overqwil",
  "Enamorus": "Enamorus"
}
